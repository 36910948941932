import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import DialogFeedback from "./DialogFeedback";
import { ProductDto } from "../../types/product/product.types";
import { sendMetrik } from "../../utils/metriks";

interface ChoiceCarCoursesProps {
    certificates: ProductDto[] | undefined,
    categoryId: number
}

const ChoiceCarCourses = ({ certificates, categoryId }: ChoiceCarCoursesProps) => {
    const [openDialogSert, setOpenDialogSert] = useState(false);
    const [idproduct, setIdProduct] = useState(0);
    const [idcategory, setIdCategory] = useState(categoryId);

    const priceFormatter = (value: number): string => {
        let result: string = ""
        let tmp: string[] = ('' + value).split('').reverse();
        let diffIndex: number = 2;

        tmp.forEach((item, index) => {
            result = item + result;
            if (index % diffIndex === 0 && index !== 0) {
                result = ' ' + result;
                diffIndex = diffIndex + 3;
            }
        })
        return result;
    }

    const sendFormData = (idProduct: number, idCategory: number) => {
        // Метрика
        sendMetrik('reachGoal', 'order-call-click');

        setIdProduct(idProduct);
        setIdCategory(idCategory);
        setOpenDialogSert(true);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: { xl: 'center', lg: "end" },
                backgroundColor: "#ffe500",
                pt: { md: "60px", xs: "40px" },
                px: { md: "auto", sm: "20px", xs: "10px" },
                pb: { sm: "12px", xs: 0 },
                boxShadow: "-4px 15px 21px 0px #000 inset",
                clipPath: { md: "polygon(0% 0%, 100% 0%, 100% 100%, 16.5% 100%)", sm: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" },
                mb: "50px"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    pl: { xl: "150px", lg: "70px", md: "100px" },
                    pr: { lg: "auto", md: "20px" }
                }}
            >
                {certificates?.map((item, ind) => (
                    <Box key={ind}
                        sx={{
                            display: "flex",
                            mb: { lg: "50px", md: "20px", sm: "50px", xs: "40px" },
                        }}
                    >
                        <Box
                            sx={{
                                mr: "10px"
                            }}
                        >
                            <DialogFeedback productId={idproduct} categoryId={idcategory} openStatus={openDialogSert} onChangeOpenModal={() => setOpenDialogSert(false)} />
                            <Button
                                onClick={() => sendFormData(Number(item.id), categoryId)}
                                sx={{
                                    width: { md: "auto", xs: "120px" },
                                    fontWeight: 400,
                                    fontSize: { md: "16px", xs: "13px" },
                                    lineHeight: { md: "24px", xs: "13px" },
                                    letterSpacing: "0.15px",
                                    textTransform: "none",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    boxShadow: { md: "4px 4px 8px 0px #000", xs: "none" },
                                    borderRadius: 0,

                                    "&:hover": {
                                        backgroundColor: "#000",
                                        color: "#fff",
                                    }
                                }}
                            >
                                Купить сертификат
                            </Button>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    backgroundColor: "#000",
                                    clipPath: { lg: "polygon(0% 0%, 100% 0%, 100% 100%, 36.5% 100%)", md: "polygon(0% 0%, 100% 0%, 100% 100%, 22.5% 100%)" },
                                    py: "5px",
                                    pl: { md: "45px", xs: "10px" },
                                    pr: { md: "10px", xs: "5px" },
                                    height: { md: "55px", xs: "40px" },
                                    /*TODO: Не срабатывает эффект тени*/
                                    boxShadow: "0px 4px 8px 0px #000",
                                    order: { md: 0, xs: 1 }
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: { md: "16px", xs: "12px" },
                                        lineHeight: { md: "15px", xs: "12px" },
                                        letterSpacing: "0.15px",
                                        textAlign: { md: "end", xs: "center" },
                                        width: "105px",
                                        mt: "2px"
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: { md: "20px", xs: "14px" },
                                        lineHeight: { md: "30px", xs: "18px" },
                                        letterSpacing: "0.19px",
                                        textAlign: { md: "end", xs: "center" },
                                        mt: { md: "2px", xs: 0 }
                                    }}
                                >
                                    {priceFormatter(item.price / 100)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default ChoiceCarCourses;