import { Paper, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ImageDto } from "../../types/common/common.types";

function Item(props: any) {

    const onClickCarousel = (href: string | null) => {
        if (href != null && href !== '') {
            document.location.href = props.href;
        }
    }

    return (
        <Paper
            onClick={() => onClickCarousel(props.href)} // Переход на страницу CarPark
            sx={{
                position: "relative",
                backgroundColor: "white",
                color: "#fff",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                justifyContent: "center",
                alignItems: "center",
                height: { sm: "440px", xs: "280px" },
                display: "flex",
                flexDirection: "column",
                borderRadius: "0",
                cursor: "pointer",
                boxSizing: "border-box",
                p: { sm: "28px 16px 60px", xs: "23px 10px 20px" },
                transition: "height 0.8s ease",

                "&:hover": {
                    height: `${props.hover ? "480px" : "none"}`
                }
            }}
            elevation={10}
        >
            <img src={`${process.env.REACT_APP_BASEURL}${props.image}`} alt="car" style={{ objectFit: "cover" }} width="100%" height="100%" />
            <Typography sx={{
                width: "100%",
                color: "#000",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "19px",
                textAlign: "end",
                mt: "10px"
            }}>
                {props.title}
            </Typography>
        </Paper>
    );
}

interface ItemMainCarouselProps {
    elStart: number,
    listSlider: ImageDto[] | undefined,
    onChangeActive: (value: number) => void,
    autoplay: boolean
}

const ItemMainCarousel = ({ elStart, listSlider, onChangeActive, autoplay }: ItemMainCarouselProps) => {
    const onChangeActiveNow = (now: any) => {
        onChangeActive(now);
    }

    return (
        <Carousel
            onChange={(now) => { onChangeActiveNow(now) }}
            autoPlay={autoplay}
            animation="slide"
            indicators={true}
            swipe={true}
            index={elStart} // Начало слайдера
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={true}
            fullHeightHover={false}
            navButtonsProps={{ // Стилизация кнопок переключения по бокам
                style: {
                    marginTop: "-35px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    backgroundColor: "#ffe500",
                    color: "#000"
                }
            }}
            indicatorIconButtonProps={{ // Стилизация индикаторов внизу (пассивный)
                style: {
                    backgroundColor: "black",
                    marginRight: "10px",
                    color: "black",
                    marginTop: "15px",
                    width: "25px",
                    height: "25px"
                }
            }}
            activeIndicatorIconButtonProps={{ // Стилизация индикаторов внизу (активный)
                style: {
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                    width: "25px",
                    height: "25px"
                }
            }}
            sx={{
                maxWidth: "775px",
                flexGrow: 1,
                margin: "auto",
                mt: 5,
                zIndex: 0
            }}
        >
            {listSlider?.map((item, i) => (
                <Item key={i} href={item.href} image={item.src} title={item.title} hover={autoplay} />
            ))}
        </Carousel>
    )
}

export default ItemMainCarousel;