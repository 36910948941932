import { Box, Divider, Typography, List, ListItem } from "@mui/material";

const contentEvents = {
    title: {
        organization: "Организация мероприятий от",
        team: "Kamenskiy Racing Team"
    },
    description: "Доступны разнообразные варианты проведения корпоративов — с мини-соревнованиями, на гоночной трассе, в закрытых картинг-клубах и на льду зимой.",
    programs: " Программы:",
    standart: {
        name: " Стандарт",
        duration: "Длительность: 3 часа",
        numofpeople: "Количество человек: 4",
        description: [
            "Краткая теоретическая подготовка",
            "Снабжение экипировкой",
            "3 заезда на спортивных автомобилях на выбор с опытными инструкторами",
            "первый заезд — «гоночное такси» (на пассажирском сидении)",
            "Кейтеринг, музыкальное сопровождение и закрытый бокс под мероприятие"
        ],
        cost: "Стоимость: от 195 000 рублей"
    }
}

const OrganizationTeam = () => {
    return (
        <Box sx={{
            width: { xl: "1440px" },
            m: "0 auto",
            backgroundImage: "url(../pngs/instructor-img-revert.png)",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "100%",
            backgroundPositionY: { xl: "-265px", lg: "-265px", md: "-120px", sm: "65px", xs: "30px" },
            backgroundSize: { md: "100%", sm: "75%", xs: "100%" }
        }}>
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "60px", sm: "17px", xs: "8px" }
            }} />
            <Box sx={{
                pl: { lg: "200px", md: "135px", sm: "65px", xs: "25px" },
                pb: "50px"
            }}>
                <Box sx={{
                    display: { md: "flex", sm: "block" },
                    mb: { md: "10px", sm: "25px" }
                }}>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: { sm: "24px", xs: "14px" },
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            color: "#fff"
                        }}>
                        {contentEvents.title.organization}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 900,
                            fontSize: { sm: "24px", xs: "14px" },
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            ml: { md: "10px", sm: 0 },
                            color: "#ffe500"
                        }}
                    >{contentEvents.title.team}</Typography>
                </Box>
                <Typography
                    sx={{
                        width: { md: "690px", sm: "499px", xs: "285px" },
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px",
                        mb: { sm: "30px", xs: "15px" }
                    }}
                >
                    {contentEvents.description}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px",
                        mb: { sm: "30px", xs: "15px" }
                    }}
                >
                    {contentEvents.programs}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px"
                    }}
                >
                    {contentEvents.standart.name}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px"
                    }}
                >
                    {contentEvents.standart.duration}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px"
                    }}
                >
                    {contentEvents.standart.numofpeople}
                </Typography>
                <List
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px",
                        p: 0,
                        mt: "20px"
                    }}
                >
                    {contentEvents.standart.description.map((item) => (
                        <ListItem
                            key={item}
                            sx={{
                                p: "0 0 5px 15px"
                            }}
                        >
                            &#8226; {item}
                        </ListItem>
                    ))}
                </List>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { sm: "16px", xs: "11px" },
                        lineHeight: { sm: "24px", xs: "12px" },
                        letterSpacing: "0.15px",
                    }}
                >
                    {contentEvents.standart.cost}
                </Typography>
            </Box>
        </Box>
    )
}

export default OrganizationTeam;