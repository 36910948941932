import { Link, List, ListItemButton, ListItem } from "@mui/material";
import { WhatsApp, Telegram, LocalPhone } from '@mui/icons-material';
import { sendMetrik } from '../../utils/metriks';

interface MobileMenuProps {
    onChangeMenu: (value: boolean) => void
}

const MobileMenu = ({ onChangeMenu }: MobileMenuProps) => {
    return (
        <List
            sx={{
                borderTop: "1px solid #ffe500",
                pb: 0
            }}>
            <List sx={{
                fontWeight: 200,
                fontSize: { lg: "24px", md: "17px" },
                lineHeight: "36px",
                p: 0
            }}>
                <ListItemButton
                    href='#aboutUs'
                    onClick={() => onChangeMenu(false)}
                    sx={{
                        py: 0,
                        justifyContent: "center"
                    }}>
                    О нас
                </ListItemButton>
                <ListItemButton
                    href='#prices'
                    onClick={() => onChangeMenu(false)}
                    sx={{
                        py: 0,
                        justifyContent: "center"
                    }}>
                    Цены
                </ListItemButton>
                <ListItemButton
                    href='#programsLearn'
                    onClick={() => onChangeMenu(false)}
                    sx={{
                        py: 0,
                        justifyContent: "center"
                    }}>
                    Программы обучения
                </ListItemButton>
                <ListItemButton
                    href='#contact'
                    onClick={() => onChangeMenu(false)}
                    sx={{
                        py: 0,
                        justifyContent: "center"
                    }}>
                    Контакты
                </ListItemButton>
            </List>
            <ListItem sx={{
                justifyContent: "center"
            }}>
                <Link href="https://api.whatsapp.com/send/?phone=79646426511&text&type=phone_number&app_absent=0"
                    sx={{
                        mt: "5px",
                        mr: "30px"
                    }} target="_blank">
                    <WhatsApp sx={{
                        width: "24px",
                        height: "24px",
                        fill: "#fff"
                    }} />
                </Link>
                <Link href="https://t.me/kamenskiy_racing"
                    onClick={() => sendMetrik('reachGoal', 'consultation-click')}
                    sx={{
                        mt: "5px",
                        mr: "30px"
                    }} target="_blank">
                    <Telegram sx={{
                        width: "24px",
                        height: "24px",
                        fill: "#fff"
                    }} />
                </Link>
                <Link href="tel:+74999900911"
                    onClick={() => sendMetrik('reachGoal', 'tel-click')}
                    sx={{
                        mt: "5px"
                    }} target="_blank">
                    <LocalPhone sx={{
                        width: "24px",
                        height: "24px",
                        fill: "#fff"
                    }} />
                </Link>
            </ListItem>
        </List>
    )
}

export default MobileMenu;