import { Box, Divider, Typography, List, ListItem } from "@mui/material";

const contentAdvantages = {
    advantages: "Преимущества обучения на арендных автомобилях",
    list: [
        {
            svg: "../svgs/motor.svg",
            text: "Не нужно заниматься\nобслуживанием автомобилей",
        },
        {
            svg: "../svgs/turbo.svg",
            text: "Индивидуальный подбор мощностей автомобилей\nи конфигурации под план тренировок",
        },
        {
            svg: "../svgs/wheel.svg",
            text: "Максимально эффективные тренировки, не без опасений\nза износ собственного автомобиля",
        },
        {
            svg: "../svgs/pouch.svg",
            text: "Не нужно «замораживать» деньги в автомобилях,\nкоторые простаивают в боксах между тренировками",
        }
    ]
}



const Advantages = () => {
    return (
        <>
            {/*Стилизация картинки*/}
            <style>
                {
                    `
                    @media (max-width: 900px) {
                        .advantages-svg {
                            width: 65px;
                            height: 65px;
                        }
                    }
                `}
            </style >

            <Box
                sx={{
                    width: { xl: "1440px" },
                    m: "0 auto"
                }}
            >
                <Divider sx={{
                    m: "0 auto",
                    borderTop: "0.1px solid #ffe500",
                    pt: { md: "60px", sm: "17px", xs: "8px" }
                }} />
                <Box
                    sx={{
                        pl: { lg: "216px", md: "135px", sm: "65px", xs: 0 },
                        pb: { sm: "75px", xs: "48px" }
                    }}
                >
                    <Typography
                        sx={{
                            width: { lg: "830px", md: "750px", sm: "535px", xs: "290px" },
                            color: "#fff",
                            fontWeight: 800,
                            fontSize: { lg: "52px", md: "45px", sm: "33px", xs: "18px" },
                            lineHeight: { md: "56px", sm: "42px", xs: "22px" },
                            letterSpacing: "0.3px",
                            mb: { sm: "35px", xs: "10px" },
                            ml: { xs: "25px", sm: 0 }
                        }}
                    >
                        {contentAdvantages.advantages}
                    </Typography>
                    <List>
                        {contentAdvantages.list.map((item) => (
                            <ListItem
                                key={item.svg}
                                sx={{
                                    p: 0,
                                    mb: { sm: "30px", xs: "10px" }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: { md: "112px", sm: "100px", xs: "40px" },
                                        minWidth: {md: "112px", sm: "100px", xs: "40px" },
                                        height: { md: "auto", sm: "65px", xs: "12px" },
                                        backgroundColor: "#ffe500",
                                        clipPath: { md: "polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)", sm: "polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)", xs: "polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%)" },
                                        display: "flex",
                                        justifyContent: "end"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: { sm: "block", xs: "none" }
                                        }}
                                    >
                                        <img className="advantages-svg" src={item.svg} alt="advantages" />
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: { md: "24px", sm: "16px", xs: "11px" },
                                        lineHeight: { sm: "24px", xs: "12px" },
                                        letterSpacing: "0.15px",
                                        WebkitUserModify: { sm: "read-write-plaintext-only", xs: "read-only" },
                                        ml: "16px",
                                        mr: { sm: "auto", xs: "20px" }
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    )
}

export default Advantages;