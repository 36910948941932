import { useState } from "react";
import { Box, Typography } from "@mui/material";
// import ButtonsSliderCar from "../common/ButtonsSliderCar";
import CarouselCarPark from "../common/CarouselCarPark";
import LeftRightButton from "../common/LeftRightButton";
import DescModelCar from "../common/DescModelCar";
import { PageSectionDto } from "../../types/page/page.types";
import ProductSertificate from "../common/ProductSertificate";
import ItemMainCarousel from "../common/ItemMainCarousel";

interface SliderParkProps {
    sectionImage: PageSectionDto | undefined
}

const SliderPark = ({ sectionImage }: SliderParkProps) => {
    let [numStart, setNumStart] = useState(0);

    const onChangeActiveNow = (event: number) => {
        setNumStart(event);
    }

    return (
        <Box sx={{
            width: { xl: "1440px" },
            m: "0 auto"
        }}>
            <Typography
                sx={{
                    color: "#fff",
                    fontWeight: 800,
                    fontSize: { lg: "52px", md: "45px", sm: "33px", xs: "24px" },
                    lineHeight: { md: "48px", sm: "42px", xs: "22px" },
                    letterSpacing: "0.3px",
                    mb: { sm: "45px", xs: "10px" },
                    ml: { lg: "35px", md: "45px", sm: "65px", xs: "25px" }
                }}
            >
                {sectionImage?.product?.name}
            </Typography>
            {/* Кнопки выше слайдера */}
            {/*<ButtonsSliderCar numActEl={numStart} cars={sectionImage?.images} onChangeActive={onChangeActiveNow} />*/}
            {/* Блок слайдера с кнопками */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    p: { lg: "62px", md: "31px", xs: "16px" },
                    mb: { md: "100px", xs: "30px" },
                    backgroundColor: "#ffe500",
                    boxShadow: "-4px 15px 21px 0px #000 inset",
                    clipPath: { lg: "polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%)", xs: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" },
                    position: "relative"
                }}
            >
                {/*<LeftRightButton position={true}
                    page={numStart}
                    lengthPage={sectionImage?.product?.images?.length}
                    onChangeActive={onChangeActiveNow}
                />*/}
                {/*<CarouselCarPark elStart={numStart} listSlider={sectionImage?.product?.images} onChangeActive={onChangeActiveNow} nameCar={sectionImage?.product?.name} />*/}
                <ItemMainCarousel elStart={numStart} listSlider={sectionImage?.product?.images} onChangeActive={onChangeActiveNow} autoplay={false} />
                {/*<LeftRightButton position={false}
                    page={numStart}
                    lengthPage={sectionImage?.product?.images?.length}
                    onChangeActive={onChangeActiveNow}
                />*/}
            </Box>
            {/* Описание характеристик машины */}
            <DescModelCar description={sectionImage?.product?.specs} />
            <ProductSertificate certificates={sectionImage?.product} />
        </Box >
    )
}

export default SliderPark;