import { useState } from "react";
import { Box, List, ListItem, Button } from "@mui/material";
import DescRaceTrack from "../common/DescRaceTrack";
import { PageSectionDto } from "../../types/page/page.types";

interface ProgramsLearnProps {
    section: PageSectionDto | undefined
}

const ProgramsLearn = ({ section }: ProgramsLearnProps) => {
    const [arraySourses, setArraySourses] = useState(false); // переменная для активации кнопок

    return (
        <Box
            sx={{
                width: { xl: "1440px" },
                m: "0 auto"
            }}
        >
            <Box
                sx={{
                    ml: { lg: "225px", md: "135px", sm: "65px", xs: "25px" },
                }}
            >
                <List
                    sx={{
                    }}
                >
                    <ListItem
                        style={arraySourses ? { justifyContent: "start" } : { justifyContent: "flex-end" }}
                        sx={{
                            p: 0
                        }}
                    >
                        {arraySourses ?
                            <DescRaceTrack contentTrack={section?.categories} />
                            :
                            <Button
                                onClick={() => { setArraySourses(true) }}
                                sx={{
                                    width: { lg: '1000px', md: "770px", sm: "500px", xs: "295px" },
                                    textAlign: { sm: "start", xs: "center" },
                                    justifyContent: { sm: "start", xs: "center" },
                                    backgroundColor: "#ffe500",
                                    fontWidth: 400,
                                    fontSize: { lg: "42px", md: "32px", sm: "20px", xs: "14px" },
                                    lineHeight: { sm: "48px", xs: "18px" },
                                    letterSpacing: "0.22px",
                                    textTransform: "none",
                                    boxShadow: "5px 12px 20px -4px black inset",
                                    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%)",
                                    color: "black",
                                    border: "1px solid black",
                                    transition: "background-color 0.6s ease, color 0.6s ease, border 0.6s ease",
                                    mb: { md: "41px", sm: "20px", xs: "15px" },
                                    py: { md: "31px", sm: "10px", xs: "12px" },
                                    pl: { lg: "130px", md: "130px", sm: "75px", xs: "45px" },
                                    pr: { md: 0, xs: "35px" },
                                    minHeight: { sm: "auto", xs: "60px" },

                                    "&:hover": {
                                        backgroundColor: "black",
                                        color: "white",
                                        border: "1px solid white"
                                    }
                                }}
                            >
                                {section?.categories?.map((item, ind) => (
                                    ind === 0 ?
                                        `${item.name}`
                                        :
                                        `:-(`
                                ))}
                            </Button>
                        }
                    </ListItem>
                </List>
            </Box>
        </Box >
    )
}

export default ProgramsLearn;