import { useEffect, useState } from "react";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { getViews } from "../api/controllers/certificate-controller";
import { CertificateDtoStatus, CertificateViewFiles } from "../types/certificate/certificate.types";
import ActivatedViewFiles from "../components/certificate-view-files-page/ActivatedViewFiles";
import PaidViewFiles from "../components/certificate-view-files-page/PaidViewFiles";

const fabricView = (view: CertificateViewFiles) => {
    if (view == null) {
        return (<></>);
    }
    if (view.status === CertificateDtoStatus.ACTIVATED) {
        return (<ActivatedViewFiles viewResource={view} />)
    }
    if (view.status === CertificateDtoStatus.PAID) {
        return (<PaidViewFiles />)
    }
    return (<>
        <Typography paddingTop={2}>
            Свяжитесь с нами через
            <a style={{ color: "blue" }}
                href={"https://t.me/kamenskiy_racing"}
            >
                Telegram
            </a>
            и
            <a
                style={{ color: "darkgreen" }}
                href={"https://api.whatsapp.com/send/?phone=79646426511&text&type=phone_number&app_absent=0"}>
                WhatsApp
            </a>
        </Typography>
    </>);
}

const CertificateViewFilesPage = () => {
    const [viewResource, setViewResource] = useState<CertificateViewFiles>();

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token: string = queryParameters.get("token") || "invalidateToken";

        console.log(token)

        getViews(token).then(response => {
            console.log(response.data)
            setViewResource(response.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return viewResource ?
        (
            <Container maxWidth="md">
                <Grid container>
                    <Grid xs={2}>
                    </Grid>
                    <Grid xs={8}>
                        {
                            fabricView(viewResource)
                        }
                    </Grid>
                    <Grid xs={2}>
                    </Grid>
                </Grid>
            </Container>
        )
        :
        (
            <Container sx={{ width: '100%', background: "black" }}>
                <CircularProgress color={"info"} />
            </Container>
        );
}

export default CertificateViewFilesPage;


