import {IconButton, ImageListItem, ImageListItemBar} from "@mui/material";
import {downloadFile} from "../../api/controllers/certificate-controller";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface ReviewImageListItemProps {
    srcImage: string,
    typeIcon: string,
    title: string,
    size: number,
}

const ReviewImageListItem = (props: ReviewImageListItemProps) => {
    const { srcImage, title, typeIcon, size } = props;

    /**
     * Скачивание файла
     */
    const onSubmit = async () => {
        console.log(title);
        const url = process.env.REACT_APP_BASEURL + "/certificates/download/" + title;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <ImageListItem sx={{ minHeight: 150 }}>
            <img
                src={srcImage}
                alt={typeIcon}
                loading="lazy"
            />
            <ImageListItemBar
                title={title}
                subtitle={size}
                actionIcon={
                    <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${title}`}
                    >
                        <FileDownloadIcon onClick={onSubmit} />
                    </IconButton>
                }
            />
        </ImageListItem>
    )
}

export default ReviewImageListItem;