import { Route, Routes } from 'react-router-dom'
import MainPage from '../pages/MainPage';
import PanelPage from '../pages/PanelPage';
import CertificateViewFilesPage from '../pages/CertificateViewFilesPage';
import NotFound from '../pages/NotFound';

const Router = () => {
  return (
    <Routes>
      <Route path="*" index element={<MainPage />} />
      <Route path="/panel" element={<PanelPage />} />
      <Route path="/views" element={<CertificateViewFilesPage />} />
      <Route path="/not_found" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
