import axios from "axios";
import {getToken} from '../helpers/tokenHelper';

export const $api = axios.create({
    baseURL: `${process.env.REACT_APP_BASEURL}`,
});

export const config = () => {
    return  {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        "Authorization": getToken(),
    }
}