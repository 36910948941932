import { Box, Button, Container, Grid, Typography } from "@mui/material";

const NotFound = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <Typography variant="h1">
                            404
                        </Typography>
                        <Typography variant="h6">
                            The page you’re looking for doesn’t exist.
                        </Typography>
                        <Button sx={{
                            border: '1px solid #fff',
                            color: '#fff',
                            mt: 2
                        }} href="/">Back Home</Button>
                    </Grid>
                    <Grid xs={6}>
                        <img
                            src="https://www.webtekno.com/images/editor/default/0003/49/68674529551899935909208749f998525f2ebf13.jpeg"
                            alt=""
                            width={500} height={250}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default NotFound;