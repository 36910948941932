import { Box, Divider, Typography } from "@mui/material";

const TitleProgramsLearn = () => {
    return (
        <Box
            sx={{
                width: { xl: "1440px" },
                m: "0 auto"
            }}
        >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "34px", sm: "17px", xs: "8px" }
            }} />
            <Box
                sx={{
                    ml: { lg: "225px", md: "135px", sm: "65px", xs: "25px" },
                    pb: { md: "80px", xs: "40px" }
                }}
            >
                <Typography
                    id="programsLearn"
                    sx={{
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: { md: "52px", sm: "30px", xs: "24px" },
                        lineHeight: "36px",
                        letterSpacing: "0.17px",
                        mb: { sm: "10px", xs: "5px" }
                    }}
                >
                    Программы обучения
                </Typography>
                <Typography
                    sx={{
                        width: { md: "auto", sm: "390px", xs: "280px" },
                        fontWeight: 400,
                        fontSize: { md: "24px", sm: "18px", xs: "13px" },
                        lineHeight: { md: "36px", sm: "18px", xs: "14px " },
                        letterSpacing: "0.25px",
                        WebkitUserModify: { md: "read-write-plaintext-only", sm: "read-only" },
                        mb: { md: "15px", xs: "25px" },
                    }}
                >
                    От начальной контраварийной подготовки до полноценного
                    участия в настоящих гонках!
                </Typography>
            </Box>
        </Box>
    )
}


export default TitleProgramsLearn;