import { Box, Typography } from "@mui/material";

const PaidViewFiles = () => {
    return (
        <Box style={{ textAlign: "center" }}>
            <Typography paddingTop={2} sx={{ textAlign: "center", fontSize: 30, fontWeight: "bold" }}>Здесь будут ваши
                файлы после активации сертификата</Typography>
            <Typography paddingTop={2}>
                Оплата сертификата прошла успешно!
            </Typography>
            <Typography paddingTop={2}>
                Ждем вас на треке. Если у вас остались вопросы свяжитесь с нами по номеру телефона: +7(499)990-09-11
            </Typography>
            <Typography paddingTop={2}>
                Или свяжитесь с нами через <a style={{ color: "blue" }}
                    href={"https://t.me/kamenskiy_racing"}>Telegram</a> и <a
                        style={{ color: "darkgreen" }}
                        href={"https://api.whatsapp.com/send/?phone=79646426511&text&type=phone_number&app_absent=0"}>WhatsApp</a>
            </Typography>
        </Box>
    )
}

export default PaidViewFiles;
