import { Box, Typography } from "@mui/material";


interface DescSpecificationsProps {
    title: string,
    // meaning: string
}

const DescSpecifications = ({ title }: DescSpecificationsProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                mb: { sm: "5px", xs: "2px" }
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: { md: "26px", sm: "15px", xs: "14px" },
                    lineHeight: { md: "30px", sm: "10px", xs: "16px" },
                    color: "black",
                    mr: "10px"
                }}>
                {title}
            </Typography>
            {/*<Typography
                sx={{
                    fontWeight: 300,
                    fontSize: { md: "26px", sm: "15px", xs: "14px" },
                    lineHeight: { md: "30px", sm: "10px", xs: "16px" },
                    color: "black"
                }}>
                {meaning}
            </Typography>*/}
        </Box>
    )
}

export default DescSpecifications;