import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { PageDto, PageSectionDto } from "../types/page/page.types";
import { getPage } from "../api/controllers/web-api-controller";
import DynamicHelmet from "../components/seo/Helmet";
import NavMenu from "../components/main-page/NavMenu";
import HelloSchool from "../components/main-page/HelloSchool";
import Instructor from "../components/main-page/Instructor";
import CarDescription from "../components/main-page/CarDescription";
import CarouselsImage from "../components/main-page/CarouselsImage";
import RaceTrack from "../components/main-page/RaceTrack";
import TitleProgramsLearn from "../components/main-page/TitleProgramsLearn";
import ProgramsLearn from "../components/main-page/ProgramsLearn";
import Advantages from "../components/main-page/Advantages";
import OrganizationTeam from "../components/main-page/OrganizationTeam";
import Footer from "../components/main-page/Footer";
import SliderPark from "../components/main-page/SliderPark";

const sectionComparator = (a: PageSectionDto, b: PageSectionDto) => {
  return a.position >= b.position ? 1 : -1;
}

const MainPage = () => {
  const [page, setPage] = useState<PageDto>();
  const navigate = useNavigate();
  const [isShowPage, setIsShowPage] = useState<boolean>(false);
  let firstCategoryShow = false;

  const errno = (err: string) => {
    console.log(err);
    navigate("/not_found");
  }

  useEffect(() => {
    page?.sections.forEach(item => {
      if (item.type === 'PRODUCT_VIEW') {
        setIsShowPage(true);
        return;
      }
    })
  }, [page])

  useEffect(() => {
    const { pathname } = document.location;
    if (pathname === '/') {
      getPage('main')
        .then(res => {
          setPage(res.data)
        })
        .catch(err => errno(err))
    } else {
      getPage(pathname.replace('/', ''))
        .then(res => {
          setPage(res.data)
        })
        .catch(error => errno(error));
    }
  }, []);

  return (
    <>
      {/* seo */}
      <DynamicHelmet title={page?.name || ""} metaTags={[{ name: 'description', content: page?.name || "" }, { name: 'keywords', content: page?.keywords || "" }]} />
      {/* меню */}
      <NavMenu />
      {/* блок приветствия */}
      <HelloSchool />
      {
        page?.sections.sort(sectionComparator).map((section, index) => {
          switch (section.type) {
            case 'SLIDER':
              return <CarouselsImage sectionImage={section} key={"carousel" + index} />;
            case 'TEXT':
              if (section.name === "Илья Каменский") {
                return <Instructor
                  profession={section.title}
                  instructor={section.name}
                  text={section.text}
                  key={"instructor_" + index}
                />;
              } else {
                return <CarDescription text={section.text} key={"car_" + index} />
              }
            case 'CATEGORY':
              if (!firstCategoryShow) {
                firstCategoryShow = true;
                return section.categories?.map((category, i) => {
                  return <div key={"category_" + i}><TitleProgramsLearn /><RaceTrack category={category} /></div>;
                })
              } else {
                return <ProgramsLearn section={section} key={"programs_" + index} />;
              }
            case 'PRODUCT_VIEW':
              return <SliderPark sectionImage={section} key={"product_view_" + index} />;
          }
        })
      }
      {
        !isShowPage && <Advantages />
      }
      {
        !isShowPage && <OrganizationTeam />
      }
      <Footer />
    </>
  )
}

export default MainPage;