import {ResourceInfo} from "../resources/resources.types";
import {OrderDto} from "../orders/order.types";

export type CertificateDto = {
    id: number;
    token: string;
    status: CertificateDtoStatus;
    order: OrderDto;
    createdAt: string;
    updatedAt: string;
}

export enum CertificateDtoStatus {
    PAID = "PAID",
    NOT_PAID = "NOT_PAID",
    ACTIVATED = "ACTIVATED",
    BLOCKED = "BLOCKED",
    NOT_FOUNT = "NOT_FOUND",
    CANCELED_PAID = "CANCELED_PAID",
}

export type CertificateViewFiles = {
    token: string;
    fileNames: ResourceInfo[];
    status: CertificateDtoStatus;
}

export type CertificateEntity = {
    id: number;
    token: string;
    status: CertificateDtoStatus;
}

