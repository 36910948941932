// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DynamicHelmet = ({ title, metaTags }: { title: string, metaTags: { name: string, content: string }[] }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        {
          metaTags.map((tag, index) => <meta key={index} name={tag.name} content={tag.content} />)
        }
      </Helmet>
    </HelmetProvider>
  )
}

export default DynamicHelmet;