import { Box } from "@mui/material";
import DescSpecifications from "./DescSpecifications";
import { ProductSpec } from "../../types/product/product.types";

interface DescModelCarProps {
    description: ProductSpec[] | undefined;
}

const DescModelCar = ({ description }: DescModelCarProps) => {
    return (
        <Box
            id="programsLearn"
            sx={{
                mt: { sm: "45px", xs: "20px" },
                p: { lg: "36px 0 36px 170px", md: "36px 0 36px 85px", sm: "24px 0 18px 48px", xs: "20px 0 5px 10px" },
                backgroundColor: "#ffe500",
                width: { md: "710px", sm: "360px", xs: "300px" },
                boxShadow: "-4px 15px 21px 0px #000 inset",
                clipPath: "polygon(0% 0%, 75% 0%, 100% 100%, 0% 100%)",
                // TODO: Боковая тень
                filter: "drop-shadow(4px 4px 4px #000)",
                mb: "25px"
            }}>
            {description?.map((spec) => (
                <DescSpecifications title={spec.name} />
            ))}
        </Box>
    )
}

export default DescModelCar;