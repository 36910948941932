import { Dialog, Box, DialogTitle, TextField, Typography, Button } from '@mui/material';
import { getPayLink } from '../../api/controllers/payment-controller';
import { useRef, useState } from 'react';
import { sendMetrik } from '../../utils/metriks';

export interface DialogFeedbackProps {
    openStatus: boolean,
    onChangeOpenModal: () => void,
    categoryId: number,
    productId: number
}

interface ValidatedTextFieldProps {
    label: string,
    validator: any,
    onChange: any,
    setValue: any,
    value: string,
    error: string,
    setError: any
}

const ValidatedTextField = ({ label, validator, onChange, setValue, value, error, setError, }: ValidatedTextFieldProps) => {
    const handleChange = (e: any) => {
        const newValue = e.target.value;
        const errorMessage = validator(newValue);
        setValue(newValue);
        setError(errorMessage);
        onChange(!errorMessage);
    };
    return (
        <TextField
            sx={{ marginBottom: 2 }}
            fullWidth
            autoFocus
            label={label}
            value={value}
            onChange={handleChange}
            error={!!error}
            helperText={error}
        />
    );
};

const emailValidator = (value: string) => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
        return "Неккоректно указана почта";
    return false;
};

const nameValidator = (value: string) => {
    if (value.length < 2) return "Имя должно быть длиннее 2 символов";
    if (!/^[a-zA-Zа-яА-Я ]+$/.test(value))
        return "Имя должно содержать только буквы и пробелы";
    return false;
};

const DialogFeedback = (props: DialogFeedbackProps) => {
    const { openStatus, onChangeOpenModal, categoryId, productId } = props;
    const formValid = useRef({ email: false, dedicate: false });
    const [email, setEmail] = useState<string>("");
    const [dedicate, setDedicate] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [dedicateError, setDedicateError] = useState<string>("");

    const handleSubmit = () => {
        // Метрика
        sendMetrik('reachGoal', 'send-request');

        let callbackUrl = window.location.href;
        getPayLink({ categoryId: categoryId, email: email, callbackUrl: callbackUrl, productId: productId, dedicate: dedicate })
            .then((response) => {
                document.location.href = response.data.url;
            })
    }

    return (
        <Dialog onClose={onChangeOpenModal} open={openStatus}
            // TODO: Не применяются стили
            sx={{
                borderRadius: "45px",
                p: "5px"
            }}
        >
            <Box
                sx={{
                    border: "2px solid #ffe500",
                    p: "5px"
                }}
            >
                <DialogTitle
                    sx={{
                        textAlign: "center",
                        p: 0,
                        mb: "34px",
                        fontWeight: 700,
                        fontSize: { md: "30px", xs: "15px" },
                        lineHeight: { md: "35px", xs: "16px" }
                    }}
                >
                    Ваши данные
                </DialogTitle>
                <form action="">
                    <ValidatedTextField
                        error={dedicateError}
                        setError={setDedicateError}
                        value={dedicate}
                        setValue={setDedicate}
                        label="Имя отображаемое на сертификате"
                        validator={nameValidator}
                        onChange={(isValid: boolean) => (formValid.current.dedicate = isValid)}
                    />
                    <ValidatedTextField
                        error={emailError}
                        setError={setEmailError}
                        value={email}
                        setValue={setEmail}
                        label="Почта для отправки сертификата"
                        validator={emailValidator}
                        onChange={(isValid: boolean) => (formValid.current.email = isValid)}
                    />
                    <Box sx={{ display: 'flex' }}>
                        <svg width="35" height="25" fill="none">
                            <path d="M5.5.251a3.129 3.129 0 00-3.125 3.125v1.875H1.75A1.25 1.25 0 00.5 6.501v5a1.25 1.25 0 001.25 1.25h7.5a1.25 1.25 0 001.25-1.25v-5a1.25 1.25 0 00-1.25-1.25h-.625V3.376A3.129 3.129 0 005.5.251zM3.625 3.376c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875v1.875h-3.75V3.376zm2.5 6.702V11.5h-1.25v-1.423a1.246 1.246 0 01.909-2.295 1.25 1.25 0 01.798 1.84c-.11.19-.267.346-.457.455z" fill="#FFE500" />
                        </svg>
                        <Typography variant="body1" sx={{
                            fontSize: { md: "12px", xs: "10px" },
                            fontWeight: "300",
                            color: "#313131"
                        }}>
                            Нажимая кнопку "Купить" Вы даете согласие на обработку персональных данных
                        </Typography>
                    </Box>
                    <Button fullWidth
                        onClick={handleSubmit}
                        disabled={!formValid.current.dedicate || !formValid.current.email}
                        sx={{
                            pt: "20px",
                            pb: "32px",
                            borderTop: "5px solid #ffe500",
                            fontWeight: 700,
                            fontSize: { md: "30px", xs: "15px" },
                            lineHeight: { md: "35px", xs: "16px" },
                            textTransform: "math-auto",
                            color: "#646262",
                            background: "#ffe500",
                            transition: "background 0.8s ease, color 0.8s ease",

                            "&:hover": {
                                background: "#e0cb0deb",
                                color: "#000"
                            },

                            "&:disabled": {
                                background: "#fffe73",
                            }
                        }}
                    >
                        Купить
                    </Button>
                </form>
            </Box>
        </Dialog>
    )
}

export default DialogFeedback;