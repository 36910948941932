// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/roboto-black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/roboto-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/roboto-light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/roboto-thin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Roboto900";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("ttf");
}

@font-face {
  font-family: "Roboto300";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto300"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf");
}

@font-face {
  font-family: "Roboto100";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto100"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("ttf");
}

html {
  scroll-behavior: smooth; /* плавное прокручивание */
}
body {
  margin: 0;
  font-family: "Roboto", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,2EAAmE;AACrE;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,2EAAqE;AACvE;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,8EAAsE;AACxE;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,8EAAqE;AACvE;;AAEA;EACE,uBAAuB,EAAE,0BAA0B;AACrD;AACA;EACE,SAAS;EACT,0CAA0C;EAC1C,mCAAmC;EACnC,kCAAkC;EAClC,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;aACW;AACb","sourcesContent":["@font-face {\n  font-family: \"Roboto900\";\n  font-style: normal;\n  font-weight: 900;\n  src: local(\"Roboto\"), url(\"./fonts/roboto-black.ttf\") format(\"ttf\");\n}\n\n@font-face {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  src: local(\"Roboto\"), url(\"./fonts/roboto-regular.ttf\") format(\"ttf\");\n}\n\n@font-face {\n  font-family: \"Roboto300\";\n  font-style: normal;\n  font-weight: 300;\n  src: local(\"Roboto300\"), url(\"./fonts/roboto-light.ttf\") format(\"ttf\");\n}\n\n@font-face {\n  font-family: \"Roboto100\";\n  font-style: normal;\n  font-weight: 100;\n  src: local(\"Roboto100\"), url(\"./fonts/roboto-thin.ttf\") format(\"ttf\");\n}\n\nhtml {\n  scroll-behavior: smooth; /* плавное прокручивание */\n}\nbody {\n  margin: 0;\n  font-family: \"Roboto\", \"Arial\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: black;\n  color: white;\n  overflow-x: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
