import {CircularProgress, Container} from "@mui/material";
import {ResourceInfo} from "../../types/resources/resources.types";
import ReviewImageListItem from "./ReviewImageListItem";

interface DeterminingFileTypeProps {
    file: ResourceInfo,
}

const DeterminingFileType = (props: DeterminingFileTypeProps) => {
    const file = props.file;

    return file ? (
        <>
            {(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.filePath) ?
                <ReviewImageListItem
                    srcImage="./svgs/icon-img.svg"
                    typeIcon="image"
                    title={file.filePath} 
                    size={file.size}
                />
                : (/\.(mp4|avi|wmv|mov|flv|mkv|webm|vob|ogv|m4v|3gp|3g2|mpeg|mpg|m2v|m4v|svi|3gpp|3gpp2|mxf|roq|nsv|flv|f4v|f4p|f4a|f4b)$/i).test(file.filePath) ?
                    <ReviewImageListItem
                        srcImage="./svgs/icon-video.svg"
                        typeIcon="video"
                        title={file.filePath}
                        size={file.size}
                    />
                    :
                    <ReviewImageListItem
                        srcImage="./svgs/icon-file.svg"
                        typeIcon="file"
                        title={file.filePath}
                        size={file.size}
                    />
            }
        </>
    ) : (
        <Container sx={{ width: '100%', background: "white" }}>
            <CircularProgress />
        </Container>
    )
}

export default DeterminingFileType;