import { useState } from 'react'

import { AppBar, Box, Link, Toolbar, IconButton, List, ListItemButton } from "@mui/material";
import { WhatsApp, Telegram, LocalPhone, Menu } from '@mui/icons-material';
import { sendMetrik } from '../../utils/metriks';

import MobileMenu from '../common/MobileMenu';

const NavMenu = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "#000",
                    py: { md: "20px", xs: "11px" },
                    borderBottom: "1px solid #ffe500"
                }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: { xl: "1390px", xs: "100vw" },
                    margin: "0 auto",
                    px: { sm: "12px", md: "38px" }
                }}>
                    <a href="/#">
                        <img src="./svgs/logo.svg" className="logo" alt="logo" />
                    </a>
                    <Box sx={{
                        display: { md: 'flex', xs: 'none' },
                        mt: "14px",
                        mr: { xl: 0, lg: "40px", md: "30px" }
                    }}>
                        <List sx={{
                            display: 'flex',
                            alignItems: "flex-start",
                            fontWeight: 200,
                            fontSize: { lg: "24px", md: "17px" },
                            lineHeight: "36px",
                            p: 0
                        }}>
                            <ListItemButton
                                href='#aboutUs'
                                sx={{
                                    py: 0
                                }}
                            >
                                О нас
                            </ListItemButton>
                            <ListItemButton
                                href='#prices'
                                sx={{
                                    py: 0
                                }}
                            >
                                Цены
                            </ListItemButton>
                            <ListItemButton
                                href='#programsLearn'
                                sx={{
                                    py: 0
                                }}
                            >
                                Программы обучения
                            </ListItemButton>
                            <ListItemButton
                                href='#contact'
                                sx={{
                                    py: 0,
                                    mr: { lg: "90px", md: "15px" }
                                }}
                            >
                                Контакты
                            </ListItemButton>
                        </List>
                        <Link href="https://api.whatsapp.com/send/?phone=79646426511&text&type=phone_number&app_absent=0"
                            sx={{
                                mt: "5px",
                                mr: { sm: "24px", xs: "12px" }
                            }} target="_blank">
                            <WhatsApp sx={{
                                width: "24px",
                                height: "24px",
                                fill: "#fff"
                            }} />
                        </Link>
                        <Link href="https://t.me/kamenskiy_racing"
                            onClick={() => sendMetrik('reachGoal', 'consultation-click')}
                            sx={{
                                mt: "5px",
                                mr: { sm: "24px", xs: "12px" }
                            }} target="_blank">
                            <Telegram sx={{
                                width: "24px",
                                height: "24px",
                                fill: "#fff"
                            }} />
                        </Link>
                        <Link href="tel:+74999900911"
                            onClick={() => sendMetrik('reachGoal', 'tel-click')}
                            sx={{
                                mt: "5px",
                                mr: { sm: "24px", xs: "12px" }
                            }} target="_blank">
                            <LocalPhone sx={{
                                width: "24px",
                                height: "24px",
                                fill: "#fff"
                            }} />
                        </Link>
                    </Box>
                    {/* Мобильное меню */}
                    <Toolbar
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        sx={{
                            display: { md: "none", sm: "block" }
                        }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                        >
                            <Menu sx={{
                                width: { sm: "48px", xs: "40px" },
                                height: { sm: "48px", xs: "40px" },
                            }} />
                        </IconButton>
                    </Toolbar>
                </Box>
                {/* Номер в меню */}
                {!mobileMenuOpen ?
                    <Link href="tel:+74999900911"
                        onClick={() => sendMetrik('reachGoal', 'tel-click')}
                        sx={{
                            display: { md: "none", sm: "block" },
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "19px",
                            textAlign: "center",
                            color: "white",
                            mt: "15px"
                        }}>
                        8-(499)-990-09-11
                    </Link>
                    :
                    null
                }
                {/* Скрытое меню, для мобилы */}
                {mobileMenuOpen ? <MobileMenu onChangeMenu={() => setMobileMenuOpen(false)} /> : null}
            </AppBar>
        </>
    )
}

export default NavMenu;