import { Box, Divider, Typography } from "@mui/material";

interface CarDescriptionProps {
    text: string | undefined
}

const CarDescription = ({ text }: CarDescriptionProps) => {
    // @ts-ignore
    // @ts-ignore
    return (
        <Box
            sx={{
                width: { xl: 1440 },
                m: "0 auto",
                pr: { sm: "auto", xs: "10px" },
            }}
        >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "34px", sm: "17px", xs: "8px" }
            }} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { sm: "center", xs: "start" },
                    pl: { sm: 0, xs: "25px" }
                }}
            >
                <Typography
                    sx={{
                        width: { md: "900px", sm: "500px", xs: "300px" },
                        fontWeight: 300,
                        fontSize: { md: "24px", sm: "18px", xs: "13px" },
                        lineHeight: { sm: "32px", xs: "18px" },
                        letterSpacing: "0.25px",
                        mb: "20px"
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: text || "" }}></div>
                </Typography>
            </Box>
        </Box>
    )
}

export default CarDescription;