import {$api, config} from "..";
import {CertificateViewFiles} from "../../types/certificate/certificate.types"

export const getViews = (token: string) => {
    return $api.get<CertificateViewFiles>(`/certificates/views?token=${token}`, { headers: config()});
}

export const getCertificates = (token: string) => {
    return $api.get<string>(`/certificates/get?token=${token}`, { headers: config()});
}

export const downloadFile = (fileName: string) => {
    return $api.get<string>(`/certificates/download/${fileName}`, { headers: config()});
}