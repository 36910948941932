import { useState } from "react";
import { Box, Typography, Link, Divider } from "@mui/material";
import { Email } from '@mui/icons-material';
import { sendMetrik } from "../../utils/metriks";

const contentFooter = [
    {
        name: "Обучение дрифту и экстремальному вождению проходят на площадке автодром Дзержинский",
        address: "Московская область, г. Дзержинский, ул. Лесная, д 32а",
        link: "https://yandex.ru/maps/org/avtodrom_trening/244175590771/?from=tabbar&ll=37.885908%2C55.638154&mode=search&sll=37.885908%2C55.638130&source=serp_navig&text=автодром%20дзержинский&z=12"
    },
    {
        name: `Заезды в Сочи на "Сочи Автодром"`,
        address: "Краснодарский край, Адлер, ул. Триумфальная, 26",
        link: "https://yandex.ru/maps/org/sirius_avtodrom/1456082796/?ll=39.968778%2C43.410954&z=14"
    },
    {
        name: "Курсы спортивного вождения, заезды на гоночной трассе проходят на Moscow Raceway",
        address: "Московская область, Волоколамский административный округ, дер. Шелудьково, 39",
        link: "https://yandex.ru/maps/org/moscow_raceway/1165862977/?ll=36.270971%2C55.994572&source=serp_navig&z=16"
    }
];

const Footer = () => {
    const [onHoverSvg, setOnHoverSvg] = useState([false, false, false])

    function changeHover(ind: number, st: boolean) {
        const newArraySourses = onHoverSvg.map((el, i) => {
            if (i === ind) {
                return st;
            } else {
                if (el === true) {
                    return true;
                } else {
                    return false;
                }
            }
        });

        setOnHoverSvg(newArraySourses);
    }

    return (
        <>
            {/*Стилизация картинки*/}
            <style>
                {
                    `
                    @media (max-width: 600px) {
                        .location {
                            width: 30px;
                            height: 30px;
                        }
                    }
                `}
            </style >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "64px", sm: "32px", xs: "16px" }
            }} />
            <Box id="contact"
                sx={{
                    width: { xl: "1440px" },
                    margin: "0 auto",
                    pl: { md: "107px", sm: "45px", xs: "20px" },
                    pr: { sm: "auto", xs: "10px" },
                    pb: "50px"
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "32px",
                        lineHeight: "48px",
                        letterSpacing: "0.3px",
                        color: "#ffe500"
                    }}
                >
                    Оставить заявку
                </Typography>
                <Link target="_blank"
                    href="mailto:kamenskiy@racing-school.ru"
                    onClick={() => sendMetrik('reachGoal', 'email-click')}
                    color="inherit"
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.3px",
                        color: "#ffe500",
                        display: "flex",
                        mb: "20px"
                    }}
                >
                    <Email />
                    <Typography sx={{
                        ml: "8px"
                    }}>kamenskiy@racing-school.ru</Typography>
                </Link>
                {contentFooter.map((item, ind) => (
                    <Box sx={{
                        display: "flex",
                        mb: { sm: "auto", xs: "15px" }
                    }} key={ind}>
                        <Link href={item.link} target="_blank"
                            onMouseEnter={() => changeHover(ind, true)}
                            onMouseLeave={() => changeHover(ind, false)}
                            sx={{
                                "&:hover": {
                                    "&>div": {
                                        backgroundColor: "#000",
                                        border: "1px solid #fff"
                                    }
                                }
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                width: { md: "130px", sm: "90px", xs: "48px" },
                                justifyContent: "end",
                                backgroundColor: "#ffe500",
                                py: { sm: "5px", xs: "2px" },
                                clipPath: {
                                    md: "polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%)",
                                    sm: "polygon(0% 0%, 100% 0%, 100% 100%, 35% 100%)",
                                    xs: "polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)"
                                },
                                mb: { sm: "41px", xs: "12px" },
                                mt: { sm: 0, xs: "8px" },
                                cursor: "pointer",
                                border: "1px solid #ffe500"
                            }}>
                                {onHoverSvg[ind] ?
                                    (
                                        <img src="../svgs/locationonHover.svg"
                                            alt="location"
                                            className="location"
                                            width={72}
                                            height={72} />
                                    )
                                    :
                                    (
                                        <img src="../svgs/locationOn.svg"
                                            alt="location"
                                            className="location"
                                            width={72}
                                            height={72} />
                                    )
                                }
                            </Box>
                        </Link>
                        <Box>
                            <Typography sx={{
                                width: { md: "600px", sm: "430px" },
                                fontWeight: 700,
                                fontSize: { sm: "18px", xs: "14px" },
                                lineHeight: { md: "20px", sm: "18px", xs: "16px" },
                                letterSpacing: "0.17px",
                                color: "#fff",
                                p: { md: "8px 12px", sm: "5px 12px", xs: "5px 12px 8px 12px" }
                            }}>
                                {item.name}
                            </Typography>
                            <Typography sx={{
                                width: { md: "600px", sm: "430px" },
                                fontWeight: 400,
                                fontSize: { sm: "16px", xs: "14px" },
                                lineHeight: { md: "14px", sm: "18px", xs: "14px" },
                                letterSpacing: "0.15px",
                                opacity: "0.8",
                                pl: "12px"
                            }}>
                                {item.address}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default Footer;