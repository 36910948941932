import {useEffect, useState} from "react";
import { Box, Typography, Divider } from "@mui/material";
import ItemCarousel from "../common/ItemCarousel";
import { PageSectionDto } from "../../types/page/page.types";
import ItemMainCarousel from "../common/ItemMainCarousel";
import {ImageDto} from "../../types/common/common.types";

interface CarouselsImageProps {
    sectionImage: PageSectionDto | undefined
}

const CarouselsImage = ({ sectionImage }: CarouselsImageProps) => {
    const [numStart, setNumStart] = useState(0);
    const [originImages, setOriginImages] = useState<ImageDto[]>(sectionImage?.images || []);

    const [leftImages, setLeftImages] = useState<ImageDto[]>([]);
    const [centerImages, setCenterImages] = useState<ImageDto[]>([]);
    const [rightImages, setRightImages] = useState<ImageDto[]>([]);
    const [rightImages2, setRightImages2] = useState<ImageDto[]>([]);



    useEffect(() => {
        if (originImages === null || originImages.length === 0) return;
        if (numStart === 0 && centerImages.length === 0) {
            const images = [originImages[originImages.length -1], ...originImages]
            setCenterImages(images);
            setLeftImages(centerImages);
            setRightImages([...centerImages, originImages[originImages.length-2]]);
            setRightImages2([...centerImages, originImages[originImages.length-1]]);
            return;
        }
        if (numStart >= originImages.length + 2 ) {
            setNumStart(0);
            return;
        }

        // @ts-ignore
    }, [centerImages, numStart, originImages])

    const onChangeActiveNow = (event: number) => {
        setNumStart(event);
    }

    return (
        <Box
            id="ChooseCar"
            sx={{
                width: { xl: "1440px" },
                m: "0 auto"
            }}
        >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "60px", sm: "17px", xs: "8px" }
            }} />
            <Typography
                sx={{
                    color: "#fff",
                    fontWeight: 800,
                    fontSize: { lg: "52px", md: "45px", sm: "33px", xs: "24px" },
                    lineHeight: { md: "48px", sm: "42px", xs: "22px" },
                    letterSpacing: "0.3px",
                    mb: { sm: "45px", xs: "10px" },
                    ml: { lg: "216px", md: "135px", sm: "65px", xs: "25px" }
                }}
            >
                Наш парк автомобилей
            </Typography>
            <Box
                sx={{
                    p: { lg: "62px", md: "31px", xs: "16px" },
                    mb: { md: "100px", xs: "30px" },
                    backgroundColor: "#ffe500",
                    boxShadow: "-4px 15px 21px 0px #000 inset",
                    clipPath: { lg: "polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%)", xs: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" },
                    position: "relative"
                }}
            >
                {/* Второстепенные блоки (позади основного слайдера) */}
                <ItemCarousel elStart={numStart+1}
                    listSlider={leftImages}
                    leftMax="34%"
                    leftMin="30%"
                    zIndex={-1}
                    maxHeight="405px"
                    top="80px"
                />
                <ItemCarousel elStart={numStart-1}
                    listSlider={rightImages}
                    leftMax="15%"
                    leftMin="12%"
                    zIndex={-1}
                    maxHeight="405px"
                    top="80px"
                />
                <ItemCarousel elStart={numStart+2}
                    listSlider={rightImages2}
                    leftMax="40%"
                    leftMin="40%"
                    zIndex={-2}
                    maxHeight="360px"
                    top="100px"
                />
                <ItemMainCarousel
                    elStart={numStart}
                    listSlider={centerImages}
                    onChangeActive={onChangeActiveNow}
                    autoplay={true}
                />
            </Box>
        </Box>
    )
}

export default CarouselsImage;