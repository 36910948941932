import { Paper, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ImageDto } from "../../types/common/common.types";

function Item(props: any) {
    return (
        <Paper
            onClick={() => console.log("Переход на другую страницу")}
            sx={{
                position: "relative",
                backgroundColor: "white",
                color: "#fff",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                justifyContent: "center",
                alignItems: "center",
                height: { sm: "440px", xs: "280px" },
                display: "flex",
                flexDirection: "column",
                borderRadius: "0",
                boxSizing: "border-box",
                p: { sm: "28px 16px 60px", xs: "23px 10px 20px" },
                transition: "height 0.8s ease"
            }}
            elevation={10}
        >
            <img src={`${process.env.REACT_APP_BASEURL}${props.image}`} alt="car" style={{ objectFit: "cover" }} width="100%" height="100%" />
            <Typography sx={{
                width: "100%",
                color: "#000",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "19px",
                textAlign: "end",
                mt: "10px"
            }}>
                {props.title}
            </Typography>
        </Paper>
    );
}

interface ItemCarouselProps {
    elStart: number,
    listSlider: ImageDto[] | undefined,
    leftMax: string,
    leftMin: string,
    zIndex: number,
    maxHeight: string,
    top: string
}

const ItemCarousel = ({ elStart, listSlider, leftMax, leftMin, zIndex, maxHeight, top }: ItemCarouselProps) => {
    return (
        <Carousel
            animation="slide"
            indicators={false}
            swipe={false}
            index={elStart} // Начало слайдера
            navButtonsAlwaysVisible={false}
            navButtonsAlwaysInvisible={false}
            cycleNavigation={false}
            fullHeightHover={false}
            navButtonsProps={{ // Стилизация кнопок переключения по бокам
                style: {
                    marginTop: "-35px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    backgroundColor: "#ffe500",
                    color: "#000",
                    display: "none"
                }
            }}
            indicatorIconButtonProps={{ // Стилизация индикаторов внизу (пассивный)
                style: {
                    backgroundColor: "black",
                    marginRight: "10px",
                    color: "black",
                    marginTop: "15px",
                    width: "25px",
                    height: "25px"
                }
            }}
            activeIndicatorIconButtonProps={{ // Стилизация индикаторов внизу (активный)
                style: {
                    backgroundColor: "black",
                    color: "white",
                    width: "25px",
                    height: "25px"
                }
            }}
            sx={{
                display: { lg: "block", xs: "none" },
                boxSizing: "border-box",
                maxWidth: "775px",
                flexGrow: 1,
                margin: "auto",
                mt: 5,
                position: "absolute",
                top: `${top}`,
                left: { xl: `${leftMax}`, lg: `${leftMin}` },
                width: "775px",
                maxHeight: `${maxHeight}`,
                zIndex: `${zIndex}`
            }}
        >
            {listSlider?.map((item, i) => (
                <Item key={i} image={item.src} title={item.title} />
            ))}
        </Carousel>
    )
}

export default ItemCarousel;