import { Box, Divider, Typography, ImageList, ImageListItem } from "@mui/material";
import { CategoryDto } from "../../types/category/category.types";
import ListRaceTrack from "../common/ListRaceTrack";
import ChoiceSertification from "../common/ChoiceSertification";

interface RaceTrackProps {
    category: CategoryDto | undefined,
}

const RaceTrack = ({ category }: RaceTrackProps) => {
    return (
        <Box
            sx={{
                width: { xl: "1440px" },
                m: { md: "0 auto 108px", xs: "0 auto 40px" }
            }}
        >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "34px", sm: "17px", xs: "8px" },
                pr: { md: "34px", sm: "17px", xs: "8px" }
            }} />
            <Box
                sx={{
                    pl: { lg: "230px", md: "135px", sm: "65px", xs: "25px" },
                    pr: { sm: "60px", xs: "25px" }
                }}
            >
                <Typography
                    sx={{
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: { md: "54px", sm: "34px", xs: "24px" },
                        lineHeight: { md: "36px", sm: "32px", xs: "30px" },
                        letterSpacing: "0.22px",
                        mb: { md: "50px", sm: "25px" }
                    }}
                >
                    {category?.name}
                </Typography>
                <Box
                    sx={{
                        display: { sm: "flex", sx: "block" },
                        mb: "38px"
                    }}
                >
                    {/* img */}
                    <ImageList
                        sx={{
                            display: "block",
                            mr: { xl: "145px", lg: "25px", md: "20px", sm: "30px" },
                            px: { sm: "auto", xs: "10px" },
                            overflow: "hidden"
                        }}
                    >
                        <ImageListItem
                            sx={{
                                maxWidth: { lg: "435px", md: "400px", sm: "440px" },
                                p: { sm: "20px 10px 70px", xs: "9px 6px 30px" },
                                backgroundColor: "white"
                            }}
                        >
                            <img src={`${process.env.REACT_APP_BASEURL}${category?.imgSrc}`} alt="track" style={{ objectFit: "scale-down" }} />
                        </ImageListItem>
                    </ImageList>
                    {/* description */}
                    <Box
                        id="prices"
                        sx={{
                            width: { lg: "420px", sm: "355px" },
                            pt: { lg: "55px", md: "5px", sm: "5px" }
                        }}
                    >
                        {category?.specs.map((item, i) => (
                            <ListRaceTrack contentTrack={item} key={"category" + i} />
                        ))}
                    </Box>
                </Box>
            </Box>
            <ChoiceSertification certificates={category?.products} categoryId={Number(category?.id)} />
        </Box>
    )
}

export default RaceTrack;