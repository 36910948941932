import { Box, Divider, Typography } from "@mui/material";

const contentInstructor = {
    chempion: "Призер Чемпионата и Кубка России по АКГ",
}

interface InstructorProps {
    profession: string | undefined,
    instructor: string | undefined,
    text: string | undefined
}

const Instructor = ({ profession, instructor, text }: InstructorProps) => {
    return (
        <Box
            sx={{
                width: { xl: 1440 },
                m: "0 auto",
                backgroundImage: "url(./pngs/instructor-img.png)",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: { lg: "-180px", md: "30px", sm: "100px", xs: "-51px" },
                backgroundPositionX: {
                    xl: "-65px", lg: "-65px", md: "-90px", sm: "-92px", xs: "260px"
                },
                backgroundSize: { lg: "1700px", md: "1400px", sm: "900px", xs: "660px" },
                pr: { sm: "auto", xs: "10px" },
            }}
        >
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "34px", sm: "17px", xs: "8px" }
            }} />
            <Box
                sx={{
                    pl: { lg: "235px", md: "135px", sm: "65px", xs: "25px" }
                }}
            >
                {/* Профессия: Шеф-инструктор школы */}
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "24px",
                        lineHeight: "36px",
                        letterSpacing: "0.22px"
                    }}
                    dangerouslySetInnerHTML={{ __html: profession || "" }}
                />
                {/* Имя инструктора: Илья Каменский */}
                <Typography
                    sx={{
                        fontWeight: 900,
                        fontSize: { md: "52px", sm: "38px", xs: "28px" },
                        lineHeight: { md: "56px", sm: "32px", xs: "30px" },
                        letterSpacing: "0.25px",
                        color: "#ffe500",
                    }}
                    dangerouslySetInnerHTML={{ __html: instructor || "" }}
                />
                {/* Достижения: Призер Чемпионата и Кубка России по АКГ */}
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: { md: "29px", sm: "24px", xs: "13px" },
                        lineHeight: { sm: "56px", xs: "35px" },
                        letterSpacing: "0.25px",
                        mb: { sm: 0, xs: "20px" }
                    }}
                    dangerouslySetInnerHTML={{ __html: contentInstructor.chempion || "" }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { sm: "end", xs: "start" },
                    pl: { sm: 0, xs: "25px" }
                }}
            >
                {/* Описание: текст описание */}
                <Typography
                    sx={{
                        m: { lg: "54px 200px 275px 0", md: "54px 65px 275px 0", sm: "35px 45px 90px 0", xs: "0 0 50px" },
                        width: { md: "560px", sm: "500px", xs: "300px" },
                        fontWeight: 300,
                        fontSize: { md: "24px", sm: "18px", xs: "13px" },
                        lineHeight: { sm: "32px", xs: "18px" },
                        letterSpacing: "0.25px"
                    }}
                    dangerouslySetInnerHTML={{ __html: text || "" }}
                />
            </Box>
        </Box>
    )
}

export default Instructor;