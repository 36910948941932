import { Box, Divider, Typography, ImageList, ImageListItem } from "@mui/material";
import ListRaceTrack from "../common/ListRaceTrack";
import { CategoryDto } from "../../types/category/category.types";
import ChoiceCarCourses from "./ChoiceCarCourses";


interface DescRaceTrackProps {
    contentTrack: CategoryDto[] | undefined
}

const DescRaceTrack = ({ contentTrack }: DescRaceTrackProps) => {
    return (
        <Box>
            <Divider sx={{
                m: "0 auto",
                borderTop: "0.1px solid #ffe500",
                pt: { md: "34px", sm: "17px", xs: "8px" },
                pr: { md: "34px", sm: "17px", xs: "8px" }
            }} />
            {contentTrack?.map((item) => (
                <Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#fff",
                                fontWeight: 700,
                                fontSize: { md: "54px", sm: "34px", xs: "24px" },
                                lineHeight: { md: "36px", sm: "32px", xs: "30px" },
                                letterSpacing: "0.22px",
                                mb: { md: "50px", xs: "25px" }
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                display: { sm: "flex", sx: "block" },
                                mb: "38px"
                            }}
                        >
                            {/* img */}
                            <ImageList
                                sx={{
                                    display: "block",
                                    mr: { xl: "145px", lg: "25px", md: "20px", sm: "30px" },
                                    px: { sm: "auto", xs: "10px" },
                                    overflow: "hidden"
                                }}
                            >
                                <ImageListItem
                                    sx={{
                                        maxWidth: { lg: "435px", md: "400px", sm: "440px" },
                                        p: { sm: "20px 10px 70px", xs: "9px 6px 30px" },
                                        backgroundColor: "white"
                                    }}
                                >
                                    <img src={`${process.env.REACT_APP_BASEURL}${item?.imgSrc}`} alt="track" style={{ objectFit: "scale-down" }} />
                                </ImageListItem>
                            </ImageList>
                            {/* description */}
                            <Box
                                sx={{
                                    width: { lg: "420px", sm: "355px" },
                                    pt: { lg: "55px", md: "5px", sm: "5px" }
                                }}
                            >
                                {item.specs.map((el, ind) => (
                                    <ListRaceTrack contentTrack={el} key={ind} />
                                ))}
                            </Box>
                        </Box>
                        {item.products.length !== 0 ?
                            <ChoiceCarCourses certificates={item.products} categoryId={item.id} />
                            :
                            null
                        }
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default DescRaceTrack;