import {Box, ImageList, ImageListItem, Typography} from "@mui/material";
import DeterminingFileType from "./DeterminingFileType";
import {CertificateViewFiles} from "../../types/certificate/certificate.types";


interface ActivatedViewFilesProps {
    viewResource: CertificateViewFiles,
}

const ActivatedViewFiles = ({viewResource}: ActivatedViewFilesProps) => {
    return (
        <Box>
            <Typography sx={{textAlign: "center", fontSize: 30}}>Данные с заезда</Typography>
            <ImageList sx={{margin: "0 auto", width: 500}}>
                {
                    viewResource.fileNames.map((file) =>
                        <ImageListItem sx={{width: 200}}>
                            <DeterminingFileType file={file}/>
                        </ImageListItem>
                    )
                }
            </ImageList>
        </Box>
    );
}

export default ActivatedViewFiles;