import { List, Typography, ListItem } from "@mui/material";
import { CategorySpecDto } from "../../types/category/category.types";

interface ListRaceTrackProps {
    contentTrack: CategorySpecDto;
}

const ListRaceTrack = ({ contentTrack }: ListRaceTrackProps) => {
    return (
        <List>
            <Typography
                sx={{
                    fontWeight: 700,
                    fontSize: { md: "16px", xs: "12px" },
                    lineHeight: "24px",
                    letterSpacing: "0.15px"
                }}
            >
                {contentTrack?.name}
            </Typography>
            {contentTrack?.items.map((item: any) => (
                <ListItem key={"contentrack" + item.id}
                    sx={{
                        fontWeight: 400,
                        fontSize: { md: "16px", xs: "12px" },
                        lineHeight: { md: "19px", xs: "14px" },
                        py: { md: "8px", sm: "4px", xs: "2px" }
                    }}
                >
                    {item.value}
                </ListItem>
            ))}
        </List>
    )
}

export default ListRaceTrack;