import { Box, Divider, Button, Typography, List, ListItem } from "@mui/material";

const contentHelloSchool = {
    hello: "Добро пожаловать в гоночную школу",
    nameSchool: "Kamenskiy Racing!",
    descriptionSchool: "Здесь можно окунуться в атмосферу автоспорта, прокатиться по трассе на гоночном автомобиле и освоить технику скоростного вождения.",
    listEvents: {
        title: "Kamenskiy Racing School это:",
        el: [
            "курсы по автомобильному спорту",
            "индивидуальные занятия",
            "организация мероприятий"
        ]
    }
}

const HelloSchool = () => {
    return (
        <Box
            id='aboutUs'
            sx={{
                m: { md: "112px auto 0", xs: "127px auto 0" },
                pb: { md: "110px", sm: "55px", xs: "34px" },
                width: { xl: 1440 },
                backgroundImage: "url(./pngs/main-img.png)",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: { lg: 0, md: "75px", sm: "25px", xs: "100px" },
                backgroundPositionX: {
                    xl: "680px", lg: "680px", md: "600px", sm: "425px", xs: "100%"
                },
                backgroundSize: { lg: "720px 800px", md: "560px 645px", sm: "470px 580px", xs: "355px 375px" },
                position: "relative"
            }}
        >
            <Divider
                sx={{
                    m: "0 auto",
                    borderTop: "0.1px solid #ffe500"
                }}
            />
            <Button
                href="#prices"
                variant="contained"
                sx={{
                    position: "absolute",
                    right: 0,
                    textTransform: "none",
                    backgroundColor: "#ffe500",
                    borderRadius: 0,
                    fontWeight: 500,
                    fontSize: { sm: "24px", xs: "16px" },
                    lineHeight: "36px",
                    letterSpacing: "0.17",
                    color: "black",
                    p: { md: "12px 60px 12px 108px", sm: "8px 40px 8px 88px", xs: "6px 30px 6px 60px" },
                    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%)",
                    transition: "font-size 1s ease, padding 1s ease",
                    boxShadow: "-2px 6px 21px 0px #000 inset",

                    "&:hover": {
                        p: { md: "12px 60px 16px 108px", sm: "10px 30px 10px 80px", xs: "8px 30px 8px 70px" },
                        backgroundColor: "#ffe500",
                        fontSize: { sm: "28px", xs: "18px" },
                        boxShadow: "-2px 6px 21px 0px #000 inset"
                    }
                }}
            >
                Купить сертификат
            </Button>
            <Box
                sx={{
                    width: { sm: "auto", xs: "275px" },
                    pl: { lg: "230px", md: "120px", sm: "60px" },
                    pt: { sm: "75px", xs: "60px" },
                    m: { sm: "0", xs: "0 28px" },
                    mb: "80px"
                }}
            >
                <Typography variant="h4"
                    sx={{
                        color: "#fff",
                        fontWeight: 900,
                        fontSize: { md: "52px", sm: "38px", xs: "24px" },
                        lineHeight: { md: "56px", sm: "32px", xs: "30px" },
                        letterSpacing: "0.25px",
                        width: { md: "555px", sm: "410px" }
                    }}>{contentHelloSchool.hello}</Typography>
                <Typography variant="h6"
                    sx={{
                        color: "#ffe500",
                        fontWeight: 900,
                        fontSize: { md: "52px", sm: "38px", xs: "24px" },
                        lineHeight: { md: "56px", sm: "32px", xs: "30px" },
                        letterSpacing: "0.25px",
                        mt: "12px"
                    }}>{contentHelloSchool.nameSchool}</Typography>
                <Typography
                    sx={{
                        fontWeight: 200,
                        fontSize: { md: "20px", sm: "14px", xs: "15px" },
                        lineHeight: { md: "26px", sm: "21px" },
                        letterSpacing: "0.15px",
                        width: { sm: "381px", xs: "287px" },
                        mt: { sm: "10px", xs: "200px" }
                    }}
                >
                    {contentHelloSchool.descriptionSchool}
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: { sm: "45px", xs: "20px" },
                    p: { lg: "36px 0 36px 170px", md: "36px 0 36px 85px", sm: "24px 0 18px 48px", xs: "20px 0 5px 10px" },
                    backgroundColor: "#ffe500",
                    width: { md: "710px", sm: "360px", xs: "300px" },
                    boxShadow: "-4px 15px 21px 0px #000 inset",
                    clipPath: "polygon(0% 0%, 75% 0%, 100% 100%, 0% 100%)",
                    // TODO: Боковая тень
                    filter: "drop-shadow(4px 4px 4px #000)"
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 300,
                        fontSize: { md: "30px", sm: "15px", xs: "14px" },
                        lineHeight: { md: "35px", sm: "10px", xs: "16px" },
                        color: "black"
                    }}
                >
                    {contentHelloSchool.listEvents.title}
                </Typography>
                <List
                    sx={{
                        fontWeight: 300,
                        fontSize: { md: "30px", sm: "15px", xs: "14px" },
                        lineHeight: { md: "35px", sm: "10px", xs: "16px" },
                        color: "black",
                    }}
                >
                    {contentHelloSchool.listEvents.el.map((item) => (
                        <ListItem key={item}
                            sx={{
                                p: { md: "0 0 0 25px", sm: "0 0 5px 10px", xs: "0 0 0 10px" }
                            }}
                        >
                            &#8226; {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    )
}

export default HelloSchool;